<template>
    <main>
        <transition name="fade-modal">
            <modal v-if="showModal" @close="closeModal()" v-bind:img="productId">
                <!--<slot name="header">test</slot>-->
                <!--<h3 slot="header">custom header</h3>-->
            </modal>
        </transition>
        <div class="content-product">
            <!--<router-link :to="{ name: 'Product', params: { id: $route.params.id }, query: { product_id: '13' } }">Test</router-link>-->
            <div class="breadcrumb-product">
                <router-link :to="{ name: 'Home'}">home</router-link>
                /
                <span class="parent" v-show="parent3.length != 0"><a @click="changeCategoryParent(parent3Id, 1)">{{fixGreek(parent3)}}</a> / </span>
                <span class="parent" v-show="parent2.length != 0"><a @click="changeCategoryParent(parent2Id, 2)">{{fixGreek(parent2)}}</a> / </span>
                <span class="parent" v-show="parent1.length != 0"><a @click="changeCategoryParent(parent1Id, 3)">{{fixGreek(parent1)}}</a> /</span>
                <a @click="changeCategoryParent(parentId)">{{fixGreek(parent)}}</a> <span v-show="parent.length != 0">/</span> <b>{{fixGreek(category)}}</b>
                <div class="more-colors-ask" v-if="brandId2 == 4">
                    ΡΩΤΗΣΤΕ ΜΑΣ ΓΙΑ ΠΕΡΙΣΣΟΤΕΡΕΣ ΔΙΑΣΤΑΣΕΙΣ ΚΑΙ ΧΡΩΜΑΤΑ (παραδοση 3 με 5 εργασιμες) 
                </div>
            </div>

            <div class="product-frame">
                <!--<div class="brand-message" v-if="brandId != null && brandId != ''">-->
                <div class="brand-message">
                    <!--Οι τιμές ενοικίασης που αφορούν στα συνεργαζόμενα καταστήματα του λιανεμπορίου, ενδέχεται να διαφοροποιούνται ανάλογα με τις τρέχουσες προσφορές αυτών.-->
                    Οι τιμές ενοικίασης από εξωτερικούς προμηθευτές ενδέχεται να διαφοροποιούνται ανάλογα με τις τρέχουσες προσφορές αυτών.<br />
                    Οι παραδόσεις είναι άμεσες εκτός της πράσινης σήμανσης που σημαίνει 3 (τρεις) εργάσιμες.

                </div>
                <div class="product-item" v-for="product of products" v-bind:key="product">
                    <div @mouseover="product.isHover = true" @mouseleave="product.isHover = false" @click="showModalPopup(product.id)"
                         class="product-image" v-bind:id="product.id"
                         :style="{ backgroundImage: 'url(\'' + getImage(product.photo, product.photo2, product.isHover) + '\')' }">
                        <!--<div class="product-new-label" :class="getCurrentClass()">Rent</div>-->
                        <div class="product-new-label" v-show="(product.qtyRent==0 || product.isRent==1) && $route.params.id != '0'">Rent</div>
                    </div>
                    <div class="product-description">
                        <div class="product-button-frame">
                            <div class="product-add-to-basket" @click="addToBasket(product.id, product.code, product.qtyRent==0 || product.isRent==1)">Add to basket</div>
                        </div>
                        <div class="description-space">
                            <b>{{ product.code }}</b> - {{ fixGreek(product.title) }}
                        </div>
                        <!--<span class="product-wish"><a href="#"><font-awesome-icon :icon="['fa', 'heart']" /></a></span>-->
                    </div>
                    <div class="product-price" v-show="$route.params.id != '0'">
                        <div class="product-price-simple" v-show="product.priceApozimiosis==0">
                            <b>{{ numberFormat(product.price) }}€</b> - Τεμάχια: {{getStock(product.qtyRent, product.isRent)}} <span v-if="product.brandId == 13" class="status-delivery"></span>
                            <span class="mobile-add-basket">
                                <a class="shopping-cart-button"
                                   @click="addToBasket(product.id, product.code, product.qtyRent==0 || product.isRent==1)">
                                    <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                                </a>
                            </span>
                        </div>
                        <div class="product-price-multi" v-show="product.priceApozimiosis!=0">
                            <div class="left-product-cart">
                                ΕΝΟΙΚΙΑΣΗ <b>{{ numberFormat(product.price) }}€</b><br />
                                ΑΠΟΖΗΜΙΩΣΗ <b>{{ numberFormat(product.priceApozimiosis) }}€</b>
                            </div>
                            <div class="right-product-cart">
                                <span class="split-line"></span>
                                ΤΕΜΑΧΙΑ: {{getStock(product.qtyRent, product.isRent)}}
                            </div>
                            <span class="mobile-add-basket">
                                <a class="shopping-cart-button"
                                   @click="addToBasket(product.id, product.code, product.qtyRent==0 || product.isRent==1)">
                                    <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div class="product-price" v-show="$route.params.id == '0'">
                        <div class="product-price-simple">
                            <span class="old-price" v-show="product.discountOldPrice > 0">{{ numberFormat(product.discountOldPrice) }}€</span> <b>{{ numberFormat(product.salePrice) }}€</b> - Τεμάχια: {{product.qty}}
                            <span class="mobile-add-basket">
                                <a class="shopping-cart-button"
                                   @click="addToBasket(product.id, product.code, product.qtyRent==0 || product.isRent==1)">
                                    <i class="fa fa-shopping-basket" aria-hidden="true"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <pagination v-if="totalComponent > perPage" :total-pages="totalPages" :total="totalComponent" :per-page="perPage" :current-page="currentPage"
                        :has-more-pages="hasMorePages" @pagechanged="pageChange($event)">
            </pagination>
        </div>
    </main>
    <transition name="slide-fade">
        <div>
            <div v-show="$route.params.id == '0'">
                <div class="menu-left-fixed-button " :class="getCurrentClass()">
                </div>
            </div>
            <div v-show="$route.params.id != '0'">
                <div class="menu-left-fixed-button " :class="getCurrentClass()"
                     @click="openLeftMenu()"
                     v-if="!show" id="menu-left-fixed-button">
                    <!--<div>More structural elements</div>-->
                    <div>{{textMoreProps}}</div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="slide-fade">
        <!--<div class="menu-left-fixed white-style green-bg-text" @click="show=!show" id="menu-left-fixed" v-if="show">-->
        <div class="menu-left-fixed white-style " :class="getCurrentClass()" id="menu-left-fixed" v-if="show">
            <div id="mdiv" class="menu-close-product" @click="show=!show">
                <div class="mdiv">
                    <div class="md"></div>
                </div>
            </div>
            <!--<ul  @click="toggle =! toggle">-->
            <ul>
                <!--<li v-for="(category, key) of categories" v-bind:key="category">-->
                <li v-for="(category) of categories" v-bind:key="category">
                    <!--<a v-show="$route.query.type==null" @click="openChildMenu(key)">{{ fixGreek(category.title) }}</a>
                    <a v-show="$route.query.type!=null" @click="changeCategory(category.id)">{{ fixGreek(category.title) }}</a>-->

                    <a v-if="!category.hasChild" :class="[category.websiteCss]" @click="changeCategory(category.id)">{{ fixGreek(category.title) }}</a>
                    <a v-if="category.hasChild" :class="[category.websiteCss]" @click="openChildMenu(category.brandId)">{{ fixGreek(category.title) }}</a>

                    <!--<router-link v-if="!isParent()" @click="openChildMenu()"
                    :to="{ name: 'Product', params: { id: category.id } }">{{ fixGreek(category.title) }}</router-link>-->
                    <transition name="slideme">
                        <!--<ul class="menu-child-left" v-if="selected==key || category.isOpenedMenu">-->
                        <ul class="menu-child-left" v-if="selected==category.brandId">
                            <li v-for="(child) of category.children" v-bind:key="child" :id="child.id">
                                <a v-if="!child.hasChild" :class="[child.websiteCss, getCurrentClassMenu(child.id)]" @click="changeCategory(child.id)">{{ child.title }}</a>
                                <a v-if="child.hasChild" :class="[child.websiteCss]" @click="openChildMenu2(child.id, child.id)">{{ fixGreek(child.title) }} ></a>
                                <transition name="slideme">
                                    <ul class="menu-child-left" v-if="selectedChild==child.id">
                                        <li v-for="(child2) of categoriesChild" v-bind:key="child2" :id="child2.id">
                                            <a v-if="!child2.hasChild" :class="getCurrentClassMenu(child2.id)" @click="changeCategory(child2.id)">{{ child2.title }}</a>
                                            <a v-if="child2.hasChild" @click="openChildMenu2(child2.id, child2.id, 2)">{{ fixGreek(child2.title) }} ></a>
                                            <transition name="slideme">
                                                <ul class="menu-child-left" v-if="selectedChild2==child2.id">
                                                    <li v-for="(child3) of categoriesChild2" v-bind:key="child3" :id="child3.id">
                                                        <a v-if="!child3.hasChild" :class="getCurrentClassMenu(child3.id)" @click="changeCategory(child3.id)">{{ child3.title }}</a>
                                                        <a v-if="child3.hasChild" @click="openChildMenu2(child3.id, child3.id, 3)">{{ fixGreek(child3.title) }} ></a>
                                                        <transition name="slideme">
                                                            <ul class="menu-child-left" v-if="selectedChild3==child3.id">
                                                                <li v-for="(child4) of categoriesChild3" v-bind:key="child4" :id="child4.id">
                                                                    >
                                                                    <a v-if="!child4.hasChild" :class="getCurrentClassMenu(child4.id)" @click="changeCategory(child4.id)">{{ child4.title }}</a>

                                                                    <a v-if="child4.hasChild" @click="openChildMenu2(child4.id, child4.id, 4)">{{ fixGreek(child4.title) }} ></a>
                                                                    <transition name="slideme">
                                                                        <ul class="menu-child-left" v-if="selectedChild4==child4.id">
                                                                            <li v-for="(child5) of categoriesChild4" v-bind:key="child5" :id="child5.id">
                                                                                <a :class="getCurrentClassMenu(child5.id)" @click="changeCategory(child5.id)">{{ child5.title }}</a>

                                                                                <a v-if="child5.hasChild" @click="openChildMenu2(child5.id, child5.id, 5)">{{ fixGreek(child5.title) }} ></a>
                                                                                <transition name="slideme">
                                                                                    <ul class="menu-child-left" v-if="selectedChild5==child5.id">
                                                                                        <li v-for="(child6) of categoriesChild5" v-bind:key="child6" :id="child6.id">
                                                                                            <a @click="changeCategory(child5.id)">{{ child6.title }}</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </transition>
                                                                            </li>
                                                                        </ul>
                                                                    </transition>
                                                                </li>
                                                            </ul>
                                                        </transition>

                                                    </li>
                                                </ul>
                                            </transition>
                                        </li>
                                    </ul>
                                </transition>
                            </li>
                        </ul>
                    </transition>

                </li>
            </ul>
        </div>
    </transition>
    <social socialClass="social-right fixed"></social>
    <transition name="fade-modal">
        <alert :messages="errorsLocal" :info="true"></alert>
    </transition>
    <transition name="fade-modal">
        <movie v-if="showMovie" @register-clicked="openMovieForm()" @close-clicked="openMovieForm()" />
    </transition>
</template>


<script>
    import axios from 'axios'
    import jQuery from 'jquery'
    import { mapActions } from 'vuex'
    import movie from './MovieDetails.vue';
    //window.jQuery = jQuery

    export default {
        name: 'Product',
        props: {
            msg: String
        },
        components: {
            movie
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        },
        data() {
            return {
                showMovie: false,
                hover: false,
                show: false,
                toggle: false,
                showModal: false,
                productId: '',
                categoryId: '',
                category: '',
                brandId: '',
                brandId2: '',
                parent: '',
                parent1: '',
                parent2: '',
                parent3: '',
                parentId: '',
                parent1Id: '',
                parent2Id: '',
                parent3Id: '',
                parent4Id: '',
                parent5Id: '',
                parent6Id: '',
                parent7Id: '',
                parent8Id: '',
                parent9Id: '',
                parent10Id: '',
                selected: undefined,
                selectedChild: undefined,
                selectedChild2: undefined,
                selectedChild3: undefined,
                selectedChild4: undefined,
                selectedChild5: undefined,
                selectedChild6: undefined,
                selectedChild7: undefined,
                selectedChild8: undefined,
                selectedChild9: undefined,
                selectedChild10: undefined,
                textMoreProps: 'More props',
                products: [],
                categories: [],
                categoriesChild: [],
                categoriesChild2: [],
                categoriesChild3: [],
                categoriesChild4: [],
                categoriesChild5: [],
                categoriesChild6: [],
                categoriesChild7: [],
                categoriesChild8: [],
                categoriesChild9: [],
                categoriesChild10: [],
                errorsLocal: [],

                currentPage: 1,
                perPage: 24,
                totalComponent: 16,
                totalPages: 0,
                clickedButton: false,
                hasMorePages: true
            }
        },
        methods: {
            ...mapActions(["addCartItem"]),
            changeCategory(id) {
                this.$router.push({ name: 'Product', params: { id: id }, query: { type: this.$route.query.type }})
                return this.show = false;
            },
            openLeftMenu() {
                this.show = !this.show;
                this.scrollToCurrent(this.$route.params.id);
            },
            showBrandMessage() {
                if (this.brandId == null) {
                    return false;
                }
                else {
                    return true;
                }
            },
            openMovieForm() {
                this.showMovie = !this.showMovie;
            },
            scrollToCurrent(id) {
                

                setTimeout(() => {

                    const scrollElement = document.getElementById("menu-left-fixed");
                    const scrollTo = document.getElementById(id);
                    //console.log(scrollTo.scrollTop);
                    //console.log(scrollTo.offsetTop);
                    //scrollElement.scrollTop = scrollTo.getBoundingClientRect().top + window.scrollY;
                    if (scrollTo != null) {
                        scrollElement.scrollTop = scrollTo.getBoundingClientRect().top - 200;
                    }

                }, 100);
            },
            //changeCategoryParent(id) {
            changeCategoryParent(id, key) {

                this.scrollToCurrent(id);
                if (key == 1) {
                    this.selectedChild = undefined
                }
                else if (key == 2) {
                    this.selectedChild2 = undefined
                }
                else if (key == 3) {
                    this.selectedChild3 = undefined
                }
                //scrollElement.scrollTop += 25;
                //this.$router.push({ name: 'Categories', params: { id: id }, query: { type: this.$route.query.type } })
                return this.show = true;
            },
            closeModal() {
                //alert('test');
                //this.$route.params.product_id = undefined;
                this.$router.push({ name: 'Product', params: { id: this.$route.params.id }, query: { product_id: undefined, type: this.$route.query.type } })
                return this.showModal = false;
            },
            getCategories() {
                //set null variables

                if (this.$route.query.type == 'elements') {
                    this.categories = [{ title: "ΚΟΥΖΙΝΕΣ", id: 0 }, { title: "ΠΑΓΚΟΣ", id: 0 }, { title: "ΠΟΡΤΕΣ", id: 0 }, { title: "RECEPTION", id: 0 }, { title: "TAMEIO ΘΕΑΤΡΟΥ", id: 0 }];
                }
                else {
                    //alert(this.$route.params.id);
                    //alert(this.$route.query.type);
                    axios.get('/api/category/full', {
                        params: {
                            id: this.$route.params.id,
                            //type: this.$route.query.type,
                            //id: this.categoryId
                        }
                    }).then((response) => {
                        //console.log("Products: ",response.data);
                        this.categories = response.data;
                        if (response.data.length == 1) {
                            this.openChildMenu(0);
                        }
                        else {
                            //this.scrollToCurrent(this.$route.params.id);
                        }
                    })
                        .catch(function (error) {
                            alert(error);
                        });
                }
            },
            getCategoriesChild(id, type = 1) {
                axios.get('/api/category/', {
                    params: {
                        //id: this.$route.params.id
                        type: this.$route.query.type,
                        id: id
                    }
                }).then((response) => {
                    //console.log(response.data);
                    //console.log(type);

                    if (type == 1) {
                        this.categoriesChild = response.data;
                    }
                    else if (type == 2) {
                        this.categoriesChild2 = response.data;
                    }
                    else if (type == 3) {
                        this.categoriesChild3 = response.data;
                    }
                    else if (type == 4) {
                        this.categoriesChild4 = response.data;
                        //console.log(this.categoriesChild4);
                    }
                    else if (type == 5) {
                        this.categoriesChild5 = response.data;
                    }
                    else if (type == 6) {
                        this.categoriesChild6 = response.data;
                    }
                    else if (type == 7) {
                        this.categoriesChild7 = response.data;
                    }
                    else if (type == 8) {
                        this.categoriesChild8 = response.data;
                    }
                    else if (type == 9) {
                        this.categoriesChild9 = response.data;
                    }
                    else if (type == 10) {
                        this.categoriesChild10 = response.data;
                    }

                    if (response.data.length == 1) {
                        //this.openChildMenu(0);
                    }
                })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            getCurrentClassMenu(id)
            {
                //alert(this.$route.params.id);
                //console.log(id);
                return this.$route.params.id == id? 'current-class-menu':''; 
            },
            getData(pageNumber) {

                if (this.$route.params.id == '') {
                    return;
                }

                axios.get('/api/products/fullPaging', {
                    params: {
                        id: this.$route.params.id,
                        page: pageNumber,
                        pageSize: this.perPage
                    }
                }).then((response) => {
                    console.log("Products: ", response.data);
                    this.products = response.data.items;
                    this.category = response.data.category;
                    this.brandId = response.data.brandId;
                    this.brandId2 = response.data.brandId2;
                    this.parent = response.data.parent;
                    this.parent1 = response.data.parent1;
                    this.parent2 = response.data.parent2;
                    this.parent3 = response.data.parent3;
                    this.parent4 = response.data.parent4;
                    this.parent5 = response.data.parent5;
                    this.parent6 = response.data.parent6;
                    this.parent7 = response.data.parent7;
                    this.parent8 = response.data.parent8;
                    this.parent9 = response.data.parent9;
                    this.parent10 = response.data.parent10;

                    this.parentId = response.data.parentId;
                    this.parent1Id = response.data.parent1Id;
                    this.parent2Id = response.data.parent2Id;
                    this.parent3Id = response.data.parent3Id;
                    this.parent4Id = response.data.parent4Id;
                    this.parent5Id = response.data.parent5Id;
                    this.parent6Id = response.data.parent6Id;
                    this.parent7Id = response.data.parent7Id;
                    this.parent8Id = response.data.parent8Id;
                    this.parent9Id = response.data.parent9Id;
                    this.parent10Id = response.data.parent10Id;

                    this.totalComponent = response.data.totalComponent;
                    this.totalPages = response.data.totalPages;

                    ////////////////////////////////////////////////
                    //alert('');

                    if (response.data.brandId == null) {
                        this.selected = 0;
                    }
                    else {
                        this.selected = response.data.brandId;
                    }
                    

                    //this.openChildMenu2(3, this.parent1Id, 2);
                    //this.selectedChild = 3;

                    if (this.parent8Id != '' && this.parent8Id != null) {
                        this.openChildMenu2(this.parent8Id, this.parent8Id, 9);
                        this.selectedChild9 = this.parent8Id;
                    }

                    if (this.parent7Id != '' && this.parent7Id != null) {
                        this.openChildMenu2(this.parent7Id, this.parent7Id, 8);
                        this.selectedChild8 = this.parent7Id;
                    }

                    if (this.parent6Id != '' && this.parent6Id != null) {
                        this.openChildMenu2(this.parent6Id, this.parent6Id, 7);
                        this.selectedChild7 = this.parent6Id;
                    }

                    if (this.parent5Id != '' && this.parent5Id != null) {
                        this.openChildMenu2(this.parent5Id, this.parent5Id, 6);
                        this.selectedChild6 = this.parent5Id;
                    }

                    if (this.parent4Id != '' && this.parent4Id != null) {
                        this.openChildMenu2(this.parent4Id, this.parent4Id, 5);
                        this.selectedChild5 = this.parentId;
                    }

                    if (this.parent3Id != '' && this.parent3Id != null) {
                        this.openChildMenu2(this.parent3Id, this.parent3Id, 4);
                        this.selectedChild4 = this.parent3Id;
                    }

                    if (this.parent2Id != '' && this.parent2Id != null) {
                        this.openChildMenu2(this.parent2Id, this.parent2Id, 3);
                        this.selectedChild3 = this.parent2Id;
                    }

                    if (this.parent1Id != '' && this.parent1Id != null) {
                        this.openChildMenu2(this.parent1Id, this.parent1Id, 2);
                        this.selectedChild2 = this.parent1Id;
                    }

                    if (this.parentId != '' && this.parentId != null) {
                        this.openChildMenu2(this.parentId, this.parentId);
                        this.selectedChild = this.parentId;
                    }

                    //this.openChildMenu(0);
                    ////////////////////////////////////////////////
                })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            isParent() {
                return this.$route.params.id == '' || this.$route.params.id == undefined;
            },
            openFrame() {
                this.menu.toggle('slide', { direction: 'left' }, 500);

            },
            showModalPopup(id) {
                this.productId = this.getImage(id);
                //this.showModal = true;
                this.$router.push({ name: 'Product', params: { id: this.$route.params.id }, query: { product_id: id, type: this.$route.query.type } })

            },
            addToBasket(id, code, rent) {
                //product.qtyRent==0 || product.isRent==1

                //if (!this.showMovie)
                //{
                //    this.openMovieForm();
                //    return;
                //}

                if (this.buttonClicked) {
                    //this.errorsLocal = ['Wait'];
                    //return;
                }
                else {
                    this.buttonClicked = true;
                }

                if (this.$route.params.id == '0')
                {
                    this.errorsLocal = ['Για περισσότερες πληροφορίες συμπληρώστε τη φόρμα επικοινωνίας ή καλέστε μας στο 213-0332316'];
                    //setTimeout(this.closeAlert, 2000);
                    return;
                }

                if (rent) {
                    this.errorsLocal = ['Ελλειψη στοκ'];
                    return;
                }

                var item = {
                    id: id,
                    qty: 1,
                    code: code,
                    //orderId: this.getUID(),
                    tempId: this.getUID()
                };

                //this.addCartItem(item);

                this.$store.dispatch('addCartItem', item)
                    .then((resp) => {
                        if (!resp.data.success) {
                            if (resp.data.errorCode == 100) {
                                this.openMovieForm();
                                return;
                            }
                            else {
                                this.errorsLocal = [resp.data.errorMessage];
                            }
                        }
                        else
                        {
                            this.errorsLocal = ['Το προϊόν προστέθηκε με επιτυχία στο καλάθι αγορών σας!'];
                            //console.log("product added!");
                        }

                        this.buttonClicked = false;

                        //console.log(resp.data);
                        //if (resp.data == 0) {
                        //    this.errorsLocal = ['Ελλειψη στοκ'];
                        //    //console.log("MIssing stock!");
                        //}
                        //else {
                        //    this.errorsLocal = ['Το προϊόν προστέθηκε με επιτυχία στο καλάθι αγορών σας!'];
                        //    //console.log("product added!");
                        //}

                        setTimeout(this.closeAlert, 2000);
                        
                    })
                    .catch(err => console.log(err))

            },
            closeAlert() {
                this.errorsLocal = [];
            },
            getImage2() {
                return require('@/assets/sample.jpg');
            },
            getImage(photo, photo2 = "", isHover = false) {
                //return '//recall.gr/images/eshop/' + photo;
                //console.log('photo', photo);

                if (photo2 != null && photo2 != "") {

                    if (isHover) photo = photo2;
                    
                }

                var s = String(photo);

                if (s.toString().indexOf('https://') !== -1)
                {
                    return photo;
                }
                else
                {
                    return '//' + this.$imageDomain + '/images/eshop/' + photo;
                }
            },
            openChildMenu(key) {
                this.categoriesChild = [];
                this.categoriesChild2 = [];
                this.categoriesChild3 = [];
                this.categoriesChild4 = [];
                this.categoriesChild5 = [];
                this.categoriesChild6 = [];
                this.categoriesChild7 = [];
                this.categoriesChild8 = [];
                this.categoriesChild9 = [];
                this.categoriesChild10 = [];
                this.parent = '';
                this.parent1 = '';
                this.parent2 = '';
                this.parent3 = '';
                this.parent4 = '';
                this.parent5 = '';
                this.parent6 = '';
                this.parent7 = '';
                this.parent8 = '';
                this.parent9 = '';
                this.parent10 = '';

                //console.log(key);
                //this.toggle = !this.toggle;
                if (this.selected == key) {
                    this.selected = undefined
                }
                else {
                    //console.log(key);
                    this.selected = key;
                }
                
            },
            openChildMenu2(key, id, type = 1) {

                //console.log('key', key);
                //console.log('id', id);
                //console.log('type', type);

                if (type == 1) {
                    if (this.selectedChild == key) {
                        this.selectedChild = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild = key;
                    }
                }
                else if (type == 2) {
                    if (this.selectedChild2 == key) {
                        this.selectedChild2 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild2 = key;
                    }
                }
                else if (type == 3) {
                    if (this.selectedChild3 == key) {
                        this.selectedChild3 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild3 = key;
                    }
                }
                else if (type == 4) {
                    if (this.selectedChild4 == key) {
                        this.selectedChild4 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild4 = key;
                    }
                    //console.log(this.selectedChild4 == key);
                }
                else if (type == 5) {
                    if (this.selectedChild5 == key) {
                        this.selectedChild5 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild5 = key;
                    }
                }
                else if (type == 6) {
                    if (this.selectedChild6 == key) {
                        this.selectedChild6 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild6 = key;
                    }
                }
                else if (type == 7) {
                    if (this.selectedChild7 == key) {
                        this.selectedChild7 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild7 = key;
                    }
                }
                else if (type == 8) {
                    if (this.selectedChild8 == key) {
                        this.selectedChild8 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild8 = key;
                    }
                }
                else if (type == 9) {
                    if (this.selectedChild9 == key) {
                        this.selectedChild9 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild9 = key;
                    }
                }
                else if (type == 10) {
                    if (this.selectedChild10 == key) {
                        this.selectedChild10 = undefined
                    }
                    else {
                        this.getCategoriesChild(id, type);
                        this.selectedChild10 = key;
                    }
                }

            },
            getCurrentClass() {
                if (this.$route.query.type == '1') {
                    this.textMoreProps = "More structural elements";
                    return '';
                }
                else {
                    this.textMoreProps = "More props";
                    return 'green-bg-text';
                }
            },
            pageChange(pageNumber) {
                this.currentPage = pageNumber;
                this.getData(pageNumber);
            }
        },
        watch: {
            //$route() {
            //    this.getData();
            //},
            '$route.params.id': function () {
                if (this.$route.params.id != undefined) {
                    this.currentPage = 1;
                    this.getData();
                    this.categoryId = this.$route.params.id;
                }
                else {
                    this.categoryId = '0';
                }
            },
            '$route.query.product_id': function () {
                //this.showModalPopup(id);
                
                if(this.$route.query.product_id != undefined)
                {
                    this.showModal = true;
                }
            },
            '$route.query.type': function () {

                if (this.$route.query.type == '1') {
                    //alert('test');
                }
            }
        },
        mounted() {

            //this.getData();
            this.getCategories();
            if (this.$route.query.product_id != undefined) {
                this.showModal = true;
            }
            this.getData(this.currentPage);

            jQuery(document).ready(function () {
                //alert('test');
            })
            

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    .old-price {
        text-decoration: line-through;
    }
</style>
<style scoped>
    .status-delivery {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 51%;
        background-color: darkgreen;
        margin-left: 5px;
    }
    .brand-message {
        color: red;
        text-align: center;
        width: 100%;
        margin-bottom: 30px;
        line-height: 22px;
    }
    .current-class-menu {
        text-decoration: underline;
        color: #ffc501;
    }
    .more-colors-ask {
        text-transform: uppercase;
        color: yellow;
        margin: 10px 0 0 0px;
        color: #ffc501;
        font-weight: 400;
    }
    .menu-left-fixed > ul > li > a {
        font-size: 22px;
        letter-spacing: 1.7px;
    }
    .split-line {
        background-color: #008089;
        width: 2px;
        height: 28px;
        display: inline-block;
    }
    .product-price-simple, .product-price-multi {
        display: inline-block;
        width: 100%;
    }
    .left-product-cart {
        display: inline-block;
        width: 50%;
        text-align: right;
        line-height: 20px;
        padding-right: 5px;
    }
    .right-product-cart {
        display: inline-block;
        width: 50%;
        text-align: left;
        padding-right: 5px;
    }
    .parent {
        font-weight: normal !important;
    }
    .breadcrumb-product a {
        cursor: pointer;
    }
    .content-product {
        font-family: Ubuntu;
        margin: 15% auto 0 auto;
        width: 80%;
        font-family: 'Ubuntu Condensed';
    }

    .product-frame {
        display: inline-flex;
        flex-wrap: wrap;
        align-content: flex-start;
        width: 100%;
    }

    .product-item {
        width: 23%;
        margin: 2% 1%;
        height: auto;
        position: relative;
        font-family: 'Bebas Neue';
        letter-spacing: 1px;
    }

    .product-description {
        margin: 15px 0 0 0;
        padding-bottom: 5px;
        border-bottom: 2px solid black;
        margin-bottom: 5px;
        position: relative;
        text-transform: uppercase;
        min-height: 65px;
        line-height: 20px;
        overflow: visible;
    }
    .description-space {
        height: 65px;
        line-height: 20px;
        overflow: hidden;
    }
    .product-price {
        text-align: center;
    }

    .product-wish {
        float: right;
    }

    .product-add-to-basket {
        width: 100%;
        position: absolute;
        bottom: -30px;
        left: 0;
        text-transform: uppercase;
        background-color: #e1e1e1;
        text-align: center;
        font-size: 1em;
        transition: bottom 0.2s;
        padding: 5px 0;
        cursor:pointer;
    }

    .product-button-frame {
        overflow: hidden;
        position: relative;
        width: 100%;
        position: absolute;
        top: -45px;
        left: 0;
        height: 30px;
    }

    .product-item:hover .product-add-to-basket {
        bottom: 0;
    }

    .product-new-label {
        text-transform: uppercase;
        right: -4px;
        position: absolute;
        top: 20%;
        background-color: #E33642;
        padding: 3px 10px;
        font-size: 0.7em;
        color: white;
        letter-spacing: 2px;
    }

    .breadcrumb-product {
        text-transform: uppercase;
        margin: 1% 1% 0 1%;
    }

        .breadcrumb-product span {
            font-weight: bold;
        }

    .product-image {
        width: 100%;
        height: 0px;
        padding-bottom: 86.25%;
        background-color: gray;
        position: relative;
        cursor: pointer;
        /*background-size: auto 100%;*/
        background-size: cover;
        background-position: center center;
    }

    .menu-left-fixed a{
        cursor:pointer;
        font-family:'Bebas Neue';
        letter-spacing:1px;
    }
    .slide-fade-enter-active {
        transition: all .3s ease-out;
    }

    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateX(-120px);
        opacity: 0;
    }

    .menu-child-left {
        margin: 20px 20px;
    }

    .is-open {
        opacity: 1;
        height: auto;
    }

    .slidedown-enter-active,
    .slidedown-leave-enter {
        opacity: 0;
        height: 0;
        transition: all .3s ease-out;
    }

    .slidedown-enter,
    .slidedown-leave-to {
        opacity: 1;
        height:auto;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: 0.5s;
    }

    .slideme-enter {
        opacity: 0;
        transform: scale3d(2, 0.5, 1) translate3d(400px, 0, 0);
    }

    .slideme-enter-to {
        transform: scale3d(1, 1, 1);
        transition: 0.5s opacity;
    }

    .slideme-enter-active,
    .slideme-leave-active {
        transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    .slideme-leave {
        transform: scale3d(1, 1, 1);
        transition: 0.5s opacity;
    }

    .slideme-leave-to {
        opacity: 0;
        transform: scale3d(2, 0.5, 1) translate3d(-400px, 0, 0);
    }

    .rotate-enter {
        transform: perspective(500px) rotate3d(0, 1, 0, 90deg);
    }

    .rotate-enter-active,
    .rotate-leave-active {
        transition: 0.5s;
    }

    .rotate-leave-to {
        transform: perspective(500px) rotate3d(0, 1, 0, -90deg);
    }


    .menu-left-fixed {
        overflow-y: scroll;
        height: 100%;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ffc500;
    }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background: #c79b06;
        }

    /* Track */
    .green-bg-text::-webkit-scrollbar-track {
        background: #017f8a;
    }

    /* Handle */
    .green-bg-text::-webkit-scrollbar-thumb {
        background: #ffffff;
    }

        /* Handle on hover */
        .green-bg-text::-webkit-scrollbar-thumb:hover {
            background: #017f8a;
        }
    /*
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
    }*/
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }
    .menu-close-product {
        right: 10px!important;
        top: 10px!important;
    }
    .mobile-add-basket {
        display: none;
    }
    @media screen and (max-width: 1060px) {
        .split-line {
            width:100%;
            height:2px;
            display:none;
        }
        .left-product-cart {
            display: block;
            width: 100%;
            text-align: center;
            line-height: 20px;
            padding-right: 5px;
            /*border-bottom: #008089 solid 2px;*/
        }

        .right-product-cart {
            display: inline-block;
            width: auto;
            text-align: center;
            padding-right: 5px;
            line-height: 20px;
        }
    }
    @media screen and (min-width: 580px) {
        .menu-left-fixed {
            min-width: 400px;
        }
    } 
    @media screen and (max-width: 580px) {

        .menu-left-fixed {
            width:100%;
        }

        .mobile-add-basket {
            display: inline-block;
            margin-left: 6px;
        }

        .description-space {
            font-size: 13px;
        }

        .product-item {
            width: 46%;
            margin: 2% 2%;
            height: 317px;
        }

        .product-image {
            width: 100%;
            height: 108px;
        }

        product-description {
            font-size: 12px;
        }

        .menu-left-fixed-button {
            width: 30px;
            font-size: 13px;
        }
    }
    @media screen and (max-width: 400px) {
        .product-item {
            height: 274px;
        }
    }
</style>
