<template>
    <main>
        <div class="content-text content-text-cart">
            <div class="row" v-if="!orderActive">
                <div class="col-sm-12">
                    <div class="order-complete">
                        <!--Thank you for the order!-->
                        <!--Αγαπητέ/ή {{name}}
                        <br />
                        σας ευχαριστούμε για τη παραγγελία σας στη Prop n Roll!
                        <br />
                        Την επεξεργαζόμαστε και σύντομα θα λάβετε νέα μας-->

                        Η παραγγελία σας βρίσκεται στο στάδιο της επεξεργασίας. <br />
                        Σύντομα θα λάβετε τα νέα μας.<br /><br />
                        Με εκτίμηση<br />
                        Ομάδα της Prop n Roll
                    </div>
                </div>
            </div>
            <div class="row" v-if="orderActive">
                <div class="col-sm-8">
                    <div class="cart-qty">
                        BASKET [{{cartQuantity}}]
                    </div>
                    <div class="button-add-basket-frame">
                        <div v-if="showScanner" id="qr-reader"></div>
                        <input placeholder="Πληκτρολογήστε κωδικό" type="text" class="input-code" id="input-code" v-model="code" />
                        <span class="button-view-basket-add " @click="addCode()"> ΠΡΟΣΘΗΚΗ</span>
                        <span class="button-view-basket-add " @click="addScanner()"> SCANNER</span>
                        <span class="product-rent" v-if="isRentProduct">Το προϊόν δεν είναι διαθέσιμο</span>
                        <!--<span class="product-rent" v-if="isRentProduct">Το προϊόν είναι νοικιασμένο</span>-->
                    </div>
                    <div class="row" >
                        <div class="col-sm-12">
                            <div class="scroll-classic">
                                <div class="cart-item" v-for="cartItem in cartItems.basketItems" :key="cartItem.id">
                                    <!--<CartListItem :cartItem="cartItem" />-->
                                    <div>
                                        <div class="row row-item">
                                            <div class="item-image">
                                                <div class="product-image-list-item"
                                                     :style="{ backgroundImage: 'url(\'' + getPhoto(cartItem.product.photo) + '\')' }">
                                                </div>
                                            </div>
                                            <div class="item-text">
                                                <div class="cart-item-title">{{ cartItem.product.code }} - {{ cartItem.product.title }} <span @click="removeItem(cartItem)" class="cart-delete-button">x</span></div>
                                                <div class="cart-item-price">
                                                    {{ numberFormat(cartItem.price) }} &euro;
                                                    <span class="cart-qty-mines" @click="addToBasket(cartItem.productId, cartItem.product.code, cartItem.qty, -1)">-</span>
                                                    {{ cartItem.qty }}
                                                    <span class="cart-qty-plus" @click="addToBasket(cartItem.productId, cartItem.product.code, cartItem.qty, 1)">+</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="button-view-basket-frame">
                        <span class="button-view-basket-delete " @click="removeAllProducts()"> Empty Basket</span>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="summary-frame" >
                        <div class="order-summary">
                            Order Summary:
                        </div>
                        <div class="cart-total">
                            ΣΥΝΟΛΟ
                            <span class="cart-total-price">
                                {{numberFormat(cartItems.totalPriceWithTax)}} <span class="cart-total-price-tax">(με ΦΠΑ)</span> &euro;
                            </span>

                            <!--<span class="tax-incl">tax excl.</span>-->
                        </div>
                        <div class="cart-fields">
                            <div class="movie-details" v-if="cartItems.customer != null" >
                                Επωνυμια: <span v-if="cartItems.customer != null && cartItems.customer.name != null">{{cartItems.customer.name}}</span> <br />
                                Τιτλος: <span>{{cartItems.name}}</span> <br />
                                Ημ/νια Παραλαβης: <span>{{dateFormat(cartItems.startDate)}}</span><br />
                                Ημ/νια Επιστροφης: <span>{{dateFormat(cartItems.finishDate)}}</span><br />
                            </div>
                            <div class="input_container">
                                <input type="text" class="awsome_input" v-model="name" placeholder="ΟΝΟΜΑ*" />
                                <span class="awsome_input_border" />
                            </div>
                            <!--<div class="input_container">
        <input type="text" class="awsome_input" v-model="company" placeholder="ΕΤΑΙΡΕΙΑ" />
        <span class="awsome_input_border" />
    </div>
    <div class="input_container">
        <input type="text" class="awsome_input" v-model="title" placeholder="ΤΙΤΛΟΣ ΤΑΙΝΙΑΣ" />
        <span class="awsome_input_border" />
    </div>-->
                            <div class="input_container">
                                <input type="text" class="awsome_input" v-model="email" placeholder="EMAIL*" />
                                <span class="awsome_input_border" />
                            </div>
                            <div class="input_container">
                                <input type="text" class="awsome_input" v-model="phone" placeholder="ΤΗΛΕΦΩΝΟ*" />
                                <span class="awsome_input_border" />
                            </div>
                            <div class="email-note">
                                <!--Αν θέλεις μπορείς να μοιραστείς τη παραγγελία με τους συνεργάτες σου.-->
                                Χρήσιμο θα ήταν για την ταχύτερη εξυπηρέτηση σας να συμπληρωθούν τα παρακάτω emails των ειδικοτήτων πλην της δική σας.
                            </div>
                            <div class="input_container">
                                <input type="text" class="awsome_input" v-model="production_email" placeholder="PRODUCTION MANAGER EMAIL" />
                                <span class="awsome_input_border" />
                            </div>
                            <div class="input_container">
                                <input type="text" class="awsome_input" v-model="art_email" placeholder="ART DIRECTOR EMAIL" />
                                <span class="awsome_input_border" />
                            </div>
                            <div class="input_container">
                                <input type="text" class="awsome_input" v-model="props_email" placeholder="PROPS MASTER EMAIL" />
                                <span class="awsome_input_border" />
                            </div>
                            <div class="order-text">
                                Για τη δέσμευση των props απαιτείται η εξόφληση του κόστους ενοικίασης στο<br /><br />
                                ΙΒΑΝ GR 6601101220000012200607807 ΕΘΝΙΚΗ ΤΡΑΠΕΖΑ<br /><br />
                                <!--Στο προκαταβαλλόμενο ποσό συμπεριλαμβάνονται:<br />
                                ΦΠΑ 24%<br />
                                Aμπαλάζ<br /><br />-->
                                Σε περίπτωση ακύρωσης πέραν του 24 ωρου από την ημερομηνία παραγγελίας, θα
                                παρακρατείται αυτούσια η εξόφληση.
                            </div>
                            <div class="button-view-basket-frame">
                                <span class="button-view-basket" @click="sendOrder"> ΑΠΟΣΤΟΛΗ ΠΑΡΑΓΓΕΛΙΑΣ</span>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <alert :messages="errorsLocal" :info="true"></alert>
    <transition name="fade-modal">
        <movie v-if="showMovie" @register-clicked="openMovieForm()" @close-clicked="openMovieForm()" />
    </transition>
</template>

<script>
    import axios from 'axios'
    import { Html5QrcodeScanner } from 'html5-qrcode';
    import { mapGetters, mapActions } from "vuex";
    import movie from './MovieDetails.vue';

    //import CartListItem from "./ListItem";

    export default {
        name: 'Cart',
        components: {
            movie
        },
        data() {
            return {
                showScanner: false,
                showMovie: false,
                phone: '',
                email: '',
                art_email: '',
                props_email: '',
                production_email: '',
                name: '',
                orderActive: true,
                errorsLocal: [],
                isRentProduct: false,
                scannedCode: '',
                order: null,
                sending: false
            }
        },
        beforeCreate: function () {
            document.body.className = 'black-style';
        },
        methods: {
            checkFields() {
                if (!this.name || !this.email || !this.phone
                    //|| !this.art_email || !this.props_email || !this.production_email
                )
                {
                    this.errorsLocal = ["Please fill all the required fields!"];
                    return false;
                }
                else if (!this.validEmail(this.email)) {
                    this.errorsLocal = ["Check your email"];
                    return false;
                }
                //else if (!this.validEmail(this.art_email)) {
                //    this.errorsLocal = ["Check art email"];
                //    return false;
                //}
                //else if (!this.validEmail(this.props_email)) {
                //    this.errorsLocal = ["Check props email"];
                //    return false;
                //}
                //else if (!this.validEmail(this.production_email)) {
                //    this.errorsLocal = ["Check production email"];
                //    return false;
                //}
                else {
                    return true;
                }

            },
            openMovieForm() {
                this.showMovie = !this.showMovie;
            },
            sendOrder() {

                if (this.sending) {
                    //alert('Περιμένετε...');
                    this.errorsLocal = ['Περιμένετε...'];
                    return;
                }

                if (this.checkFields()) {
                    let data = {
                        name: this.name,
                        email: this.email,
                        phone: this.phone,
                        tempId: this.getUID(),
                        art_email: this.art_email,
                        props_email: this.props_email,
                        production_email: this.production_email,
                    }
                    this.sending = true;
                    axios.post('/api/cart/send', data).then((response) => {
                        //commit('UPDATE_CART_ITEMS', response.data)
                        //console.log(response.data);
                        if (response.data.success) {
                            this.removeUID();
                            this.closeOrder();
                            this.orderActive = false;
                            this.sending = false;
                        }
                        else
                        {
                            this.errorsLocal = [response.data.errorMessage];
                        }
                    });
                }

            },
            addToBasket(id, code, currentQty, qty) {

                var item = {
                    id: id,
                    qty: qty,
                    code: code,
                    tempId: this.getUID()
                };

                if ((currentQty + qty) === 0) {
                    this.removeCartItem(item);
                }
                else {
                    this.addCartItem(item);
                }

                
            },
            addCode() {
                //alert(this.code);
                if (this.code == undefined || this.code?.trim() == '') {
                    this.errorsLocal = ["Enter the code!"];
                    return;
                }

                this.isRentProduct = false;

                //alert(this.code);

                var item = {
                    id: 0,
                    qty: 1,
                    code: this.code,
                    tempId: this.getUID()
                };

                this.addCartItem(item).then(response => {
                    //console.log("Got some data, now lets show something in this component");
                    //console.log(response.data);
                    //if (response.data.error != null) {
                    //    this.isRentProduct = true;
                    //}
                    //if (response.data == -1 || response.data == 0) {
                    //    this.isRentProduct = true;
                    //}
                    //console.log(response);
                    if (!response.data.success) {
                        if (response.data.errorCode == 100) {
                            this.openMovieForm();
                            return;
                        }
                        else {
                            this.errorsLocal = [response.data.errorMessage];
                            //this.isRentProduct = true;
                        }
                    }
                    else {
                        this.errorsLocal = ['Το προϊόν προστέθηκε με επιτυχία στο καλάθι αγορών σας!'];
                        //this.getDataMovie();
                        //console.log("product added!");
                    }
                    
                }, error => {
                    //console.error("Got nothing from server. Prompt user to check internet connection and try again");
                    console.log(error.data);
                });

                this.code = "";

            },
            removeItem(model) {

                var item = {
                    id: model.productId,
                    qty: 1,
                    tempId: this.getUID()
                };

                this.removeCartItem(item);

            },
            removeAllProducts() {

                this.removeAllCartItems(this.getUID());
                this.removeUID();

            },
            //getDataMovie() {
            //    axios.get('/api/cart/getOrder/' + this.getUID())
            //        .then((response) => {
            //        this.order = response.data;
            //        //console.log(response.data);
            //    });
            //},
            getTotalTax(total) {

                let parcedTotal = parseFloat(total);
                let tax = parcedTotal * 0.24;
                let totalWithTax = parcedTotal + tax;

                //return total + ' + (' + tax + ' φπα) = ' + totalWithTax;
                //return totalWithTax.toFixed(2) + ' (' + tax.toFixed(2) + ' φπα)';
                return totalWithTax.toFixed(2);

            },
            addScanner()
            {

                this.showScanner = !this.showScanner;

                if (this.showScanner)
                {
                    this.createScan();
                }
                
            },
            createScan() {

                setTimeout(() => {

                    //const config = { fps: 10, qrbox: 250 };
                    const config = { fps: 2};
                    const html5QrcodeScanner = new Html5QrcodeScanner(
                        "qr-reader",
                        config
                    );
                    html5QrcodeScanner.render(this.onScanSuccess);

                }, "200");

            },
            //onScanSuccess(decodedText, decodedResult) 
            onScanSuccess(decodedText) 
            {
                //console.log(decodedText);
                //this.code = decodedText;

                if (this.scannedCode != decodedText)
                {
                    this.code = decodedText;
                    this.scannedCode = decodedText;
                    this.addCode();
                }

                //document.getElementById('input-code').innerHTML = 'test';
                //this.$emit('input-code', decodedText, decodedResult);

                //html5QrcodeScanner.clear();
            },
            onCloseButton() {
                this.$emit('close-clicked')
            },
            ...mapActions(["removeAllCartItems"]),
            ...mapActions(["addCartItem"]),
            ...mapActions(["removeCartItem"]),
            ...mapActions(["closeOrder"])
        },
        computed: {
            ...mapGetters(["cartItems", "cartTotal", "cartQuantity"]),
        },
        created() {
            //this.$store.dispatch("getCartItems");
            this.$store.dispatch('getCartItems', this.getUID());
            //this.getDataMovie();
            //this.createScan();
            //this.onScanSuccess('asdfasd');

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .email-note {
        margin: 23px 0 17px 0;
        line-height: 24px;
        color: lightslategray;
        border: 1px solid orange;
        padding: 5px;
    }
    .movie-details {
        text-align: left;
        line-height: 32px;
        margin-bottom: 34px;
        text-transform: uppercase;
    }
    .movie-details span{
        font-weight:bold;
    }
    #qr-reader {
        margin-bottom: 20px;
    }
    .order-text {
        text-align: left;
        margin-top: 15px;
        line-height: 22px;
    }
    .order-complete {
        text-align: center;
        font-size: 1.7em;
        line-height: 43px;
    }
    .scroll-classic {
        max-height: 600px;
    }
    .button-view-basket-frame {
        cursor:pointer;
    }
    .cart-fields {
        margin-top: 50px;
    }
    .input_container ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: black;
    }

    .input_container ::-moz-placeholder { /* Firefox 19+ */
        color: black;
    }

    .input_container :-ms-input-placeholder { /* IE 10+ */
        color: black;
    }

    .input_container :-moz-placeholder { /* Firefox 18- */
        color: black;
    }
    .input_container {
        display: inline-block;
        text-align: center;
        width: 80%;
        margin-top: 10px;
    }
    .awsome_input {
        padding: 5px 10px 0px 0;
        border: none;
        background: transparent;
        display: block;
        font-size: 1em;
        width: 100%;
        color: black;
    }
    .awsome_input_border {
        top: -3px;
    }
    .summary-frame {
        max-width: 300px;
        margin:0 auto;
    }
    .tax-incl {
        font-weight: normal;
        display: block;
        padding-top: 13px;
        font-size: .6em;
        font-family: Roboto;
    }
    .cart-total-price {
        float: right;

    }
    .cart-total-price-tax {
        font-weight:100;

    }
    .cart-total {
        text-align: left;
        font-family: 'Bebas Neue';
        font-size: 1.3em;
        font-weight: bold;
        margin-top: 40px;
    }
    .content-text-cart {
        margin-bottom: 50px;
    }
    .cart-item {
        font-family: 'Bebas Neue';
    }
    .cart-item-price {
        text-align: right;
        margin-right: 20px;
    }
    .order-summary {
        font-size: 3em;
        margin-top: 68px;
        font-family: 'Bebas Neue';
        font-weight: bold;
        letter-spacing: 3px;
    }
    .content-text {
        width: 80% !important;
    }
    .button-view-basket-delete {
        width: 200px;
        text-align: center;
        display: block;
        text-transform: uppercase;
        padding: 10px 0;
        cursor: pointer;
        color: black;
        background-color: white;
        border: 1px solid black;
        font-weight: bold;
    }
    .button-view-basket-add {
        width: 200px;
        text-align: center;
        display: inline-block;
        text-transform: uppercase;
        padding: 10px 0;
        cursor: pointer;
        color: black;
        background-color: white;
        border: 1px solid black;
        font-weight: bold;
        margin: 0 0 0 10px;
    }
    .product-rent {
        color:red;
    }
    .button-add-basket-frame {
        border-bottom: 2px solid black;
        text-align: left;
        padding-bottom: 20px;
        margin-bottom: 40px;
        letter-spacing: 2px;
        font-weight: bold;
    }
    .input-code {
        width: 240px;
        height: 38px;
        padding:0 10px 0 10px;
    }
    .cart-qty-mines, .cart-qty-plus {
        display: inline-block;
        padding: 10px;
        cursor: pointer;
        font-size: 18px;
        font-family: 'Bebas Neue';
        padding-top: 10px;
    }
    .cart-qty-mines{
        margin-left:50px;
    }
    .cart-delete-button {
        position: absolute;
        right: 0px;
        bottom: 3px;
        cursor: pointer;
        font-size: 14px;
    }
    .cart-item-title {
        border-bottom: solid 1px black;
        text-align: left;
        padding: 20px 0 10px 0;
        position: relative;
    }
    .item-text {
        text-transform: uppercase;
        width: calc(100% - 156px);
    }
    .product-image-list-item {
        width: 140px;
        height: 140px;
        position: relative;
        cursor: pointer;
        background-size: cover;
        background-position: center center;
    }
    .cart-qty {
        font-size: 20px;
        border-bottom: 2px solid black;
        text-align: left;
        padding-bottom: 10px;
        margin-bottom: 20px;
        font-family: 'Bebas Neue';
        letter-spacing: 2px;
        font-weight: bold;
    }
    .button-view-basket-frame {
        margin-top: 40px;
    }
    .button-view-basket {
        width: 100%;
        text-align: center;
        display: block;
        text-transform: uppercase;
        padding: 15px 0;
        color: white;
        background-color: black;
    }
    .basket-list-items {
        border: 2px solid black;
        background-color: white;
        padding: 20px;
        position: fixed;
        z-index: 999;
        top: 60px;
        right: 46px;
        margin-left: -400px;
        width: 500px;
        -webkit-box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
        box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
    }
    .text-register {
        font-size: 2em;
    }
    .input_container {
        width: 100%;
    }
    .login-frame {
        background-color: white;
        padding: 60px;
        position: fixed;
        z-index: 9;
        top: 20%;
        left: 50%;
        margin-left: -300px;
        width: 600px;
        -webkit-box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
        box-shadow: -2px 1px 13px -3px rgba(0,0,0,0.98);
    }
    .login-frame p, .login-frame h1{
        color:black;
    }
        .login-frame .awsome_input {
            font-size: 2em;
        }
        .login-frame .contact-email {
            font-family: Roboto Condensed;
            font-weight:bold;
        }
    .register-send-button {
        margin-top: 40px;
        border: 1px solid black;
        display: inline-block;
        padding: 15px 40px 10px;
        color: black;
        font-size: 2em;
    }
    .login-header {
        font-size: 2em;
        margin-bottom: 20px;
        margin-top: 30px;
    }

    @media screen and (max-width: 900px) {

        .button-add-basket-frame {
            text-align: center;
        }

        .input-code {
            margin-bottom:20px;
            
        }
        .button-view-basket-add {
            margin-left: 0px;
            margin-bottom: 20px;
        }
        .product-rent {
            display:block;
        }
    } 
    
    @media screen and (max-width: 580px) {
        .login-frame {
            width: 86%;
            margin-left: -43%;
            font-size: 100%;
        }

        .register-send-button {
            margin-bottom: 40px;
        }

        .login-header {
            margin-top: 0px;
        }

        .login-frame {
            padding: 30px 60px;
            top: 20%;
        }

            .login-frame .awsome_input {
                font-size: 1.5em;
            }

        .product-image-list-item[data-v-530ad160] {
            width: 80px;
            height: 80px;
        }

        .item-text {
            width: calc(100% - 90px);
        }
    }
</style>
