<template>
    <!--<div class="hello">
        <h1>{{ msg }}</h1>

    </div>-->
    <main>
        <!--<transition name="fade-modal">
            <div v-if="prop" class="home-image-bg home-prop">
            </div>
        </transition>
        <transition name="fade-modal">
            <div v-if="roll" class="home-image-bg home-roll">
            </div>
        </transition>-->
        <div class="left-side">
            <div class="home-frame">
                <div class="text-2">
                    <div class="text-line-fill"></div>
                    <div class="bg-color-green-bg-image text-4">FIND YOUR</div>
                </div>
                <div class="text-1">PRO<span>P</span></div>
                <div class="home-frame-button">
                    <div class="text-3 home-text-border-bottom-green">PROP</div>
                    <div class="home-text-border-bottom home-text-border-bottom-green-bg"></div>
                </div>
                <div class="text-align-left">
                    <router-link :to="{ name: 'Prop' }">
                        <span class="button-home">
                            <span class="button-home-text">RENT</span>
                            <img src="../assets/narrow.svg" />
                        </span>
                    </router-link>
                </div>
            </div>
            <!--<div @click="openTourForm()" class="button-tour button-tour-left">Live</div>-->
        </div>
        <div class="right-side">
            <div class="home-frame">
                <div class="text-2">
                    <div class="text-line-fill"></div>
                    <div class="bg-color-orange-bg-image text-4">& LET IT</div>
                </div>
                <div class="text-1">ROL<span>L</span></div>
                <div class="home-frame-button">
                    <div class="text-3 home-text-border-bottom-orange">DECOR</div>
                    <div class="home-text-border-bottom home-text-border-bottom-orange-bg"></div>
                </div>
                <div class="text-align-right" >
                    <router-link :to="{ name: 'Product', params: { id: 0 } }">
                        <span class="button-home">
                            <span class="button-home-text">SHOP</span>
                            <img src="../assets/narrow.svg" />
                        </span>
                    </router-link>
                </div>
            </div>
            <!--<div @click="openTourForm()" class="button-tour button-tour-right">Tour</div>-->
        </div>
        <div class="promotion" v-if="showPromo && promoUrl!=null">


            <div class="promotion-container">
                <div id="mdiv" class="black-close orange-close green-close" @click="closePromo()">
                    <div class="mdiv">
                        <div class="md"></div>
                    </div>
                </div>
                <img :src="promoUrl" alt="Promotion" />
            </div>


        </div>
    </main>
    <div class="terms-of-service">
        <a target="_blank" href="https://www.propnroll.com/privacy.pdf?v=1">Terms of Service</a>
    </div>
    <social></social>
    <transition name="fade-modal">
        <tour v-if="showTour" @register-clicked="openTourForm()" @close-clicked="openTourForm()" />
    </transition>
</template>


<script>
    import tour from './Tour.vue';
    import axios from 'axios';

    export default {
        name: 'Home',
        props: {
            msg: String
        },
        beforeCreate: function () {
            document.body.className = 'home white-style';
        },
        components: {
            tour
        },
        data() {
            return {
                prop: false,
                roll: false,
                showTour: false,
                showPromo: true,
                promoUrl: null
            };
        },
        methods: {
            openTourForm()
            {
                //this.showModal = true;
                this.showTour = !this.showTour;
            },
            closePromo() {
                //this.showModal = true;
                this.showPromo = !this.showPromo;
            },
            getSettings() {
                axios.get('/api/setting/', {
                    params: {
                        value: 'SettingsBannerName',
                    }
                }).then((response) => {
                    //console.log(response.data);
                    if (response.data.value != '') {
                        //this.promoUrl = '//' + this.$imageDomain + '/images/eshop/' + response.data.value;
                        this.promoUrl = this.getPhoto(response.data.value);
                    }

                    //alert(response.data.value);
                    //if (response.data.length == 1) {
                    //    alert(response.data.value);
                    //}
                })
                    .catch(function (error) {
                        alert(error);
                    });
            },
        },
        mounted() {
            //localStorage.setItem('user', 'test2');
            //this.promoUrl = '//' + this.$imageDomain + '/images/promotion_feb.gif';
            this.getSettings();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    .promotion {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        text-align: center;
        z-index: 10000;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

/*        display: flex;
        justify-content: center;*/
    }
        .promotion div.promotion-container {
            margin: 3% auto;
            display: inline-block;
            position: relative;
            height: 90%;
            max-width: 90%;
        }
        .promotion img {
            
            height: 100%;
            max-width:100%;
/*            width: calc(100% - 1000px);
            left: 50%;
            top: 50%;*/
        }
    .button-tour {
        position: absolute;
        bottom: 50px;
        width: 100px;
        text-align: center;
        padding: 10px 0 10px 0;
        text-transform: uppercase;
        font-size: 24px;
        letter-spacing: 4px;
        cursor: pointer;
    }
    .button-tour-left {
        right: 0px;
        background-color: #ffc107;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .button-tour-right {
        left: 0px;
        background-color: #17a2b8;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .button-home-text {
        position: absolute;
        font-size: 33px;
        font-weight: 100;
        letter-spacing: 4px;
        padding: 2px 0px 0 2px;
    }
    .terms-of-service {
        position: absolute;
        bottom: 32px;
        left: 40px;
    }
        .terms-of-service a {
            color: white;
            font-family: 'Ubuntu Condensed';
            font-size:18px;
        }
    .left-side {
        left: 0;
    }

    .right-side {
        right: 0;
    }
    .left-side, .right-side {
        position: absolute;
        top: 0%;
        bottom: 0;
        color: #fff;
        width: 50%;
    }
    .home-image-bg {
        background-position: center center;
        background-repeat: no-repeat;
        position: absolute;
        width: 50%;
        top: 0;
        bottom: 0;
        z-index: 0;
    }
    .home-prop {
        background-color: #016972;
        background-image: url('../assets/home-prop.jpg');
        right: 0;
        z-index: 4;
    }
    .home-roll {
        background-color: #ffc107;
        background-image: url('../assets/home-roll.jpg');
        left: 0;
        z-index: 4;
    }
    .home-frame {
        margin: 27% auto 0;
        width: 70%;

    }
    .bg-color-green-bg-image {
        background-image: url('../assets/line.jpg');
        background-repeat: repeat-y;
        background-position-x:-10px;
    }
    .bg-color-orange-bg-image {
        background-image: url('../assets/line.jpg');
        background-repeat: repeat-y;
        background-position:right;
    }

    @media screen and (max-width: 1200px) {
        .text-1 {
            font-size: 10em;
            letter-spacing: 0.15em;
        }
    }
    @media screen and (max-width: 1000px) {
        .text-1 {
            font-size: 8em;
            letter-spacing: 0.15em;
        }
    }
    @media screen and (max-width: 700px) {
        .promotion div.promotion-container img{
            height: auto;
        }
    }
    @media screen and (max-width: 580px) {
        .button-tour {
            bottom: 100px;
        }
        .promotion div.promotion-container {
            margin: 10% auto;
            height: auto;
            width: 80%;
        }
        .terms-of-service {
            bottom: 42px;
            left: 20px;
        }
        .home-frame {
            margin: 77% auto 0;
            width: 70%;
        }
        .text-1 {
            font-size: 4.5em;
            letter-spacing: 0.15em;
        }
        .text-2 {
            font-size: 1.4em;
            margin-bottom: 15px;
        }
        .text-4 {
            padding-left: 17px;
        }
    }
</style>
